import React from 'react'
import Layout from '../../containers/Layout'
import process from '../../config/text/process.json'

class Process extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/raw-coffee-beans-scoop-scales-and-bags.jpg' heroText={process.title} title={process.meta.title} description={process.meta.description}>
        <div className='container process'>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              <iframe
                width='560'
                height='315'
                title='Coffee Roasting Process'
                src='https://www.youtube.com/embed/Jwto89vZAD4'
                frameborder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              />
            </div>
            <div className='col-md-6'>
              {process.description.map(t => <p>{t}</p>)}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Process
